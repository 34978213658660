.bnpp-sustainable-investors-grid-item {

    position: relative;
    min-height: 315px;

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60%;
        background: linear-gradient(180deg, rgba(12, 39, 40, 0) 0%, rgba(12, 39, 40, 0.576565) 46%, rgba(12, 39, 40, 0.83) 65.5%, #0C2728 100%);
        transition: 300ms;
    }

    &::before {
        content: "";
        position: absolute;
        inset: 0;
        border: solid 4px var(--wp--preset--color--bnpp-green);
        z-index: 4;
        opacity: 0;
        transition: 300ms;
        background-image: var(--externalLinkIcon);
        background-repeat: no-repeat;
        background-position: 96% 3%;
    }

    &:hover, &:focus {

        &::after {
            height: 100%;
        }

        &::before {
            opacity: 1;
        }

        .bnpp-sustainable-investors-grid-item-content {
            h3 span:not(.bnpp-sustainable-investors-grid-item-hovertext) {
                opacity: 0;
                transform: translateY(40px);
            }

            p {
                display: none;
            }

            .bnpp-sustainable-investors-grid-item-hovertext {
                opacity: 1;
                color: var(--wp--preset--color--white);
                font-size: var(--wp--preset--font-size--heading-m);
                position: relative;
            }
        }

    }

    figure {
        position: absolute;
        inset: 0;
        margin: 0;

        img {
            height: 100%;
            object-fit: cover;
        }
    }

    .bnpp-sustainable-investors-grid-item-content {
        position: relative;
        z-index: 2;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: var(--wp--preset--spacing--40);

        * {
            margin: 0;
        }

        h3 {
            color: #7FD4B5;
            font-size: var(--wp--preset--font-size--heading-s);
            position: relative;

            span:not(.bnpp-sustainable-investors-grid-item-hovertext) {
                transition: 300ms;
                display: inline-block;
            }

            .bnpp-sustainable-investors-grid-item-hovertext {
                padding-top: 40px;
                display: inline-block;
            }
        }

        p {
            color: var(--wp--preset--color--white);
            font-weight: 400;
        }

        .bnpp-sustainable-investors-grid-item-hovertext {
            font-size: var(--wp--preset--font-size--heading-s);
            opacity: 0;
            position: absolute;
            font-family: var(--wp--preset--font-family--bnpp-sans-condensed);
            z-index: 3;

            &::before {
                content: "„";
                position: absolute;
                left: -10px;
                top: 0px;
                font-size: 130px;
                transform: rotate(180deg);
                color: var(--wp--preset--color--bnpp-green-tint-30);
                z-index: -1;
                line-height: 1;
                font-family: unset;
            }
        }

    }

}